import React from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import MeritHeader from '../../../components/about/MeritHeader';

export default () => (
  <Layout>
    <Seo title='¡Registrado!' keywords={[`success`, `application`, `react`]} />
    <MeritHeader>¡Gracias!</MeritHeader>
    <p>
      Ya estás inscrito en el taller Frontend Web Developer. En los próximos
      días te enviaremos por correo y WhatsApp la ubicación exacta de los
      talleres. Mantente alerta para próximos cursos y eventos.
    </p>

    <p>
      Si tienes una duda,{' '}
      <a href='http://m.me/leavetheordinary'>
        <b>
          <u>contáctanos ahora</u>
        </b>
      </a>
      .
    </p>
  </Layout>
);
